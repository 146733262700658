<template>
  <div class="sign-up-view">
    <div class="welcome-section"></div>
    <SignUp />
  </div>
</template>

<script>
import SignUp from '@/components/SignUp.vue'

export default {
  components: {
    SignUp
  }
}
</script>
