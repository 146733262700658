<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import '../src/assets/tailwind.css'
// import InputText from 'primevue/inputtext'
// import FloatLabel from 'primevue/floatlabel'


export default {
  name: 'App'
}
</script>

<style>
/* Your global styles here */
</style>
